import { FC, ReactNode } from 'react';

import LandingHeader from 'components/Headers/LandingHeader';
import LandingFooter from 'components/LandingFooter';

import { LandingContainer, Content, ContentContainer } from '../LandingLayout/styled';
import { Container } from './styled';

type LandingLayoutProps = {
  children: ReactNode;
  noBGImage?: boolean;
  keepWidth?: boolean;
  backgroundColor?: string;
  heightAuto?: boolean;
};

const InmobiLayout: FC<LandingLayoutProps> = ({
  children,
  noBGImage = false,
  keepWidth = false,
  backgroundColor,
  heightAuto,
}) => {
  return (
    <>
      <Container backgroundColor={backgroundColor} noBGImage={noBGImage} heightAuto={heightAuto}>
        <LandingContainer>
          <Content keepWidth={keepWidth}>
            <ContentContainer>
              <LandingHeader linkTo={process.env.NEXT_PUBLIC_MAIN_DOMAIN as string} showLanguagePicker={false} />
            </ContentContainer>
            {children}
          </Content>
        </LandingContainer>
      </Container>
      <LandingContainer>
        <ContentContainer>
          <LandingFooter footerType="inmobiFooter" noBGImage={noBGImage} />
        </ContentContainer>
      </LandingContainer>
    </>
  );
};

export default InmobiLayout;
